<template>
  <BaseCard>
    <header class="flex items-center justify-between">
      <div class="flex items-center">
        <span
          class="flex flex-col justify-center items-center h-10 w-10 rounded-full mr-4 bg-secondary text-white"
        >
          <Icon
            width="24px"
            height="24px"
            view-box="0 0 20 20"
          >
            <Document />
          </Icon>
        </span>
        <h2 class="text-lg">
          Logo
        </h2>
      </div>
    </header>

    <section class="my-6">
      <div>
        <label class="sr-only">
          Upload logo
        </label>
        <div
          v-if="logoUrl"
          class="my-6"
        >
          <img
            :src="logoUrl"
            alt="Logo"
            class="p-4"
            :style="logoStyle"
          >
        </div>
        <div class="flex items-center">
          <div
            v-if="processing"
            class=""
          >
            <Loader />
          </div>
          <label
            v-else
            for="logo-upload"
            class="duration-150 rounded bg-transparent hover:text-gray-700 border border-gray-500 px-4 py-2 cursor-pointer text-sm"
          >
            <span v-if="logoUrl">Replace logo</span>
            <span v-else>Add logo</span>

            <input
              id="logo-upload"
              type="file"
              accept="image/*"
              :disabled="processing"
              class="sr-only"
              @click="uploadError = ''"
              @change="uploadFile($event.target.files[0])"
            >
          </label>

          <ErrorsInline
            v-if="uploadError"
            class="relative ml-4"
          >
            <span>{{ uploadError }}</span>
          </ErrorsInline>
        </div>
      </div>
    </section>

    <header class="flex items-center justify-between">
      <div class="flex items-center">
        <span
          class="flex flex-col justify-center items-center h-10 w-10 rounded-full mr-4 bg-secondary text-white"
        >
          <Icon
            width="24px"
            height="24px"
            view-box="0 0 20 20"
          >
            <StarStandard />
          </Icon>
        </span>
        <h2 class="text-lg">
          Colour
        </h2>
      </div>
    </header>

    <section class="my-6">
      <SketchPicker
        v-model="colors"
      />
      <BaseButton
        :loading="savingColour"
        :disabled="hexColour === brandingColour"
        class="mt-4"
        @click="saveColour()"
      >
        Save
      </BaseButton>
    </section>
  </Basecard>
</template>

<script>
import Document from '@components/Icons/Document'
import StarStandard from '@components/Icons/StarStandard'
import ErrorsInline from '@components/ErrorsInline'
import Icon from '@components/Icons/Icon'
import Loader from '@components/Loader'

import organisationsApi from '@api/organisations'

import { mapGetters } from 'vuex'
import { Sketch } from 'vue-color'

export default {
  components: {
    'SketchPicker': Sketch,
    StarStandard,
    Document,
    ErrorsInline,
    Icon,
    Loader
  },

  data() {
    return {
      colors: '#FFFFFF',
      savingColour: false,

      processing: false,
      hasErrors: false,
      cloudinaryCloudName: process.env.VUE_APP_CLOUDINARY_CLOUD_NAME,
      cloudinaryApiKey: process.env.VUE_APP_CLOUDINARY_API_KEY,
      uploadError: ''
    }
  },

  computed: {
    ...mapGetters({
      organisationId: 'organisations/id',
      logoUrl: 'organisations/brandingLogoUrl',
      brandingColour: 'organisations/brandingColour'
    }),

    /**
     * @return {string}
     */
    hexColour() {
      if (typeof this.colors === 'string') {
        return this.colors
      }
      return this.colors.hex
    },

    logoStyle() {
      return 'background-color: ' + this.brandingColour + ';'
    }
  },

  mounted() {
    if (this.brandingColour) {
      this.colors = this.brandingColour
    }
  },

  methods: {
    /**
     * Start file upload
     *
     * @param {Object} file
     */
    uploadFile(file) {
      this.processing = true

      const imageData = new FormData()

      let publicId = this.organisationId
      let timestamp = Math.round(new Date().getTime() / 1000)
      const uploadPreset = 'organisation_logo'

      imageData.append('upload_preset', uploadPreset)
      imageData.append('public_id', publicId)
      imageData.append('file', file)
      imageData.append('api_key', this.cloudinaryApiKey)
      imageData.append('timestamp', timestamp)

      return organisationsApi.getCloudinarySignature({ publicId, timestamp, uploadPreset })
        .then(response => {
          imageData.append('signature', response.signature)

          organisationsApi.uploadLogo(imageData, this.cloudinaryCloudName)
            .then(image => {
              this.savePhoto({
                publicId: image.public_id,
                version: image.version,
                format: image.format,
                cloudName: this.cloudinaryCloudName
              })
            })
            .catch(error => {
              this.uploadError = 'Sorry there was a problem uploading your logo, please try again'
              this.processing = false
              throw error
            })
        })
        .catch(error => {
          this.processing = false
          throw error
        })
    },

    savePhoto(photo) {
      organisationsApi.saveLogo(this.organisationId, photo)
        .then(response => {
          this.$store.commit('organisations/organisation', response)
          this.processing = false
        })
        .catch(error => {
          this.uploadError = 'Sorry there was a problem saving your logo, please try again'
          this.processing = false
          throw error
        })
    },

    saveColour() {
      this.savingColour = true

      organisationsApi.saveColour(this.organisationId, this.hexColour)
        .then(response => {
          this.$store.commit('organisations/organisation', response)
          this.savingColour = false
        })
        .catch(error => {
          this.savingColour = false
          throw error
        })
    }
  }
}
</script>
