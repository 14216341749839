<template>
  <BaseContainer>
    <BrandingSettings />
  </BaseContainer>
</template>

<script>
import BrandingSettings from '@components/Settings/BrandingSettings'

export default {
  components: {
    BrandingSettings
  },

  page() {
    return {
      title: 'Custom Branding'
    }
  }
}
</script>
