<template>
  <BaseCard>
    <header class="flex items-center justify-between">
      <div class="flex items-center">
        <span
          class="flex flex-col justify-center items-center h-10 w-10 rounded-full mr-4 bg-secondary text-white"
        >
          <Icon
            width="24px"
            height="24px"
            view-box="0 0 20 20"
          >
            <ClipboardCheck />
          </Icon>
        </span>
        <h2 class="text-lg">
          Custom Branding
        </h2>
      </div>
    </header>
    <div class="prose mt-4">
      <p>
        Candidates you invite can be shown your logo instead of the {{ satelliteName }} logo.
      </p>
      <p>
        The logo appears at the top of the candidate’s screen. We recommend using a square, or a landscape rectangle, on a white background.
      </p>
      <p>
        You can use a JPG or PNG image, and we’ll resize it to fit the space.
      </p>
    </div>
  </Basecard>
</template>

<script>
import Icon from '@components/Icons/Icon'
import ClipboardCheck from '@components/Icons/ClipboardCheck'

export default {
  components: {
    Icon,
    ClipboardCheck
  },

  data() {
    return {
      satelliteName: process.env.VUE_APP_SATELLITE_NAME
    }
  }
}
</script>
